var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "user-item" },
        [
          _c("img", {
            attrs: { src: require("@/assets/images/avatar.png"), alt: "" },
          }),
          _c(
            "div",
            { staticClass: "content" },
            [
              _vm._l(_vm.userFields, function (field) {
                return _c("p", { key: field }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(field)) +
                      "：" +
                      _vm._s(_vm.user[field]) +
                      " "
                  ),
                ])
              }),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("role")) +
                    "：" +
                    _vm._s(_vm._f("roleFormat")(_vm.user))
                ),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("team")) +
                    "：" +
                    _vm._s(_vm._f("teamFormat")(_vm.user))
                ),
              ]),
            ],
            2
          ),
          _vm.hasPermission(_vm.$permissionFunctionCode.MANAGE_USER)
            ? _c(
                "el-dropdown",
                [
                  _c("em", { staticClass: "el-icon-more" }),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleUser("edit")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleRole.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("role")) + " ")]
                      ),
                      _vm.$store.state.user.id !== _vm.user.id
                        ? [
                            _vm.teams.length
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleUser("transfer")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("transfer")) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    _vm.currentUser.email === _vm.user.email,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleUser("lockOrUnlock")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.user.enable
                                        ? _vm.$t("lock")
                                        : _vm.$t("unlock")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                style: {
                                  color:
                                    _vm.currentUser.email === _vm.user.email
                                      ? "#bbb"
                                      : "#F56C6C",
                                },
                                attrs: {
                                  disabled:
                                    _vm.currentUser.email === _vm.user.email,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleUser("delete")
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("span", {
            style: { background: _vm.user.enable ? "#67C23A" : "#F56C6C" },
          }),
          _c("div", { staticClass: "user-type", class: _vm.setRoleColor() }, [
            _c("span", [
              _vm._v(_vm._s(_vm._f("userTypeFormat")(_vm.user.roles))),
            ]),
          ]),
        ],
        1
      ),
      _vm.editUserVisible
        ? _c("base-form-add-or-edit", {
            attrs: {
              visible: _vm.editUserVisible,
              "url-save": _vm.$apis.saveUserNew,
              "url-update": _vm.$apis.saveUserNew,
              fields: _vm.userFormFields,
              labelWidthCn: "100",
              labelWidthEn: "200",
              width: "50%",
            },
            on: {
              closed: function ($event) {
                _vm.editUserVisible = false
              },
              "submit-success": function ($event) {
                return _vm.$emit("handle-success")
              },
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.roleVisible,
            "custom-class": "role-dialog",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleVisible = $event
            },
          },
        },
        [
          _c("base-title", {
            attrs: { slot: "title", text: _vm.$t("roleList") },
            slot: "title",
          }),
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "center" } },
            [
              _c("el-transfer", {
                attrs: {
                  titles: [_vm.$t("optional"), _vm.$t("selected")],
                  props: { key: "id", label: "name" },
                  data: _vm.roleList,
                },
                model: {
                  value: _vm.userRoleIds,
                  callback: function ($$v) {
                    _vm.userRoleIds = $$v
                  },
                  expression: "userRoleIds",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function ($event) {
                      _vm.roleVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    disabled: !_vm.userRoleIds.length,
                  },
                  on: { click: _vm.submitRole },
                },
                [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.transferVisible,
            "custom-class": "transfer-dialog",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.transferVisible = $event
            },
          },
        },
        [
          _c("base-title", {
            attrs: { slot: "title", text: _vm.$t("chooseTransferTeam") },
            slot: "title",
          }),
          _c(
            "div",
            { staticClass: "team-list" },
            _vm._l(_vm.teamList, function (team) {
              return _c(
                "p",
                {
                  key: team.id,
                  class: { active: team.isIn },
                  on: {
                    click: function ($event) {
                      team.isIn = !team.isIn
                    },
                  },
                },
                [_vm._v(" " + _vm._s(team.name) + " ")]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function ($event) {
                      _vm.transferVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    disabled: !_vm.selectedTeams.length,
                  },
                  on: { click: _vm.submitTransfer },
                },
                [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }